import { Disclosure, Transition } from "@headlessui/react"
import classNames from "classnames"
import strings from "../strings.json"
import MarkdownComponent from "./MarkdownComponent"

export default function FAQ() {
  return (
    <div className="bg-black py-[64px] md:py-[100px]" id="faq">
      <div className="px-10 max-w-[745px] m-auto">
        <div className="space-y-[24px]">
          <div>
            <h2 className="text-title text-purple">
              Frequently Asked Questions
            </h2>
          </div>
          <ul className="divide-y divide-purple-border">
            {strings.faq.map((faq) => {
              const { question, answerMarkdown } = faq
              return (
                <Disclosure
                  as="div"
                  key={question}
                  className="py-[24px] space-y-[8px]"
                >
                  {({ open }) => (
                    <>
                      <div className="text-lg">
                        <Disclosure.Button className="text-left w-full text-gray-400">
                          <img
                            src={open ? "/contract.svg" : "/expand.svg"}
                            className={classNames("h-6 w-6 float-right")}
                            aria-hidden="true"
                            width={30}
                            height={30}
                          />
                          <span className="text-teal-light font-[700]">
                            {question}
                          </span>
                        </Disclosure.Button>
                      </div>
                      <Transition
                        enter="transition duration-100 ease-out"
                        enterFrom="transform scale-95 opacity-0"
                        enterTo="transform scale-100 opacity-100"
                        leave="transition duration-75 ease-out"
                        leaveFrom="transform scale-100 opacity-100"
                        leaveTo="transform scale-95 opacity-0"
                      >
                        <Disclosure.Panel as="dd" className="mt-2 pr-12">
                          <div className="text-white font-[500]">
                            <MarkdownComponent markdown={answerMarkdown} />
                          </div>
                        </Disclosure.Panel>
                      </Transition>
                    </>
                  )}
                </Disclosure>
              )
            })}
          </ul>
        </div>
      </div>
    </div>
  )
}

const QUESTIONS__TODO_MOVE_TO_STRINGS_FILE = [
  {
    question: "WTF IS THIS? COCKPUNCH? IS THIS A REAL THING?",
    answer: (
      <p>
        You bet your ass this is a real thing.
        <br />
        <br />
        The Legend of COCKPUNCH is the tale of a fantastical world, a place
        where few things are what they seem. Finally, a fictional realm named
        and built for our chaotic times! It’s the first world-building fiction
        attempt by Tim Ferriss. That’s me.
        <br />
        <br />
        This is my first big public-facing project since my last book, Tribe of
        Mentors, was published in 2017. This is also the first Emergent Long
        Fiction (ELF) project of its kind.
        <br />
        <br />
        I started buying BTC in 2012–2013, began collecting NFTs in early 2021,
        and launched my first NFT (which was also my first fiction work) earlier
        this year. However, that was all just a warm-up for the main event…
        CØCKPUNCH®!
        <br />
        <br />
        Believe it or not, I’ve been thinking about and planning this experiment
        for the better part of a year. Time flies when you’re having fun. The
        eight great houses are established, the initial legends and mythologies
        are complete, there are dozens (perhaps hundreds) of secrets waiting to
        be discovered, and… those are just the initial conditions. The Legend of
        COCKPUNCH is also a Trojan Horse for quite a lot that will become
        clearer over time. I have some good ideas of how I can shape things, and
        where I can direct things, but the vast majority of this universe will
        unfurl as it gathers steam.
        <br />
        <br />
        The whole point is to embrace fun and put irreverence at the core of
        things. So don’t take it too seriously. I’m fucking serious.
        <br />
        <br />
        For more on the genesis story of COCKPUNCH, please listen to this
        interview I did with Kevin Rose.
      </p>
    )
  },
  {
    question: "WHO IS BEHIND THIS LUNACY?",
    answer: (
      <p>
        Since all bios deserve the gravitas of third person, especially when
        associated with a high-brow brand like COCKPUNCH, here goes…
        <br />
        <br />
        Tim Ferriss is the author of five #1 New York Times and Wall Street
        Journal bestsellers, including The 4-Hour Workweek and Tools of Titans.
        The Observer and other media have called Tim “the Oprah of audio” due to
        the influence of The Tim Ferriss Show podcast, which has now exceeded
        900 million downloads, 600+ guests, and was featured as “Best Of” on
        Apple Podcasts three years in a row. Tim is also an early-stage
        technology investor/advisor (Uber, Facebook, Shopify, Duolingo, and 50+
        others) and is founder of the Saisei Foundation, a nonprofit foundation
        focused on cutting-edge scientific research.
      </p>
    )
  },
  {
    question:
      "HAVE YOU GONE OFF OF YOUR MEDS? HAVE YOU JOINED THE DARK SIDE? WHY WOULD YOU DO THIS WITH YOUR CAREER (SUCH AS IT IS)?",
    answer: (
      <p>
        I’ve long wanted to do a big experiment with fiction and art,
        resurrecting those old passions. I just couldn’t find the right vehicle.
        The conventional options just didn’t click. Then, in early 2021, the
        author Steven Pressfield said the following to me:
        <br />
        <br />
        [Going big] doesn’t mean it necessarily has to be 800 pages long, but
        just a big idea. An idea that’s kind of a scary idea, that you say to
        yourself, “When I show this to people, they’re going to look at me and
        go, ‘What happened to you, Tim? Are you okay?'” That’s what I mean by
        big.
        <br />
        <br />
        Shortly thereafter, I began dreaming bigger, dreaming stranger, and
        allowing myself to veer into the bizarre. Allowing myself to grab a few
        glasses of wine at night, sketch out the truly bat-shit crazy ideas, and
        go fully off-menu. Ultimately, I landed on what I’m doing now, because
        making COCKPUNCH allows me to:
        <br />
        <br />
        a) Do a deep dive on a new set of technologies,
        <br />
        <br />
        b) Connect and collaborate with a bunch of gifted people in the space
        (Kevin Rose, Brenden Mulligan, Cozomo de’ Medici, and many others),
        <br />
        <br />
        c) Create a tiny team and deadlines to keep myself motivated and
        accountable,
        <br />
        <br />
        d) Do some wild experiments that would be impossible with traditional
        book publishing and other arenas where I have a lot of experience.
        <br />
        <br />
        e) Raise money for the non-profit Saisei Foundation, which has supported
        a lot of firsts in mental health therapeutics (for PTSD,
        treatment-resistant depression, end-of-life anxiety, etc.), psychedelic
        science, etc. I wouldn’t be here today if it weren’t for the types of
        projects that Saisei supports.
      </p>
    )
  },
  {
    question: "HOW WILL PRIMARY SALE PROCEEDS BE USED?",
    answer: (
      <p>
        100% of proceeds from the primary sale will go to The Saisei Foundation.
        <br />
        <br />
        The Saisei Foundation is a registered 501(c)(3) non-profit private
        foundation that funds cutting-edge scientific research and other
        initiatives related to psychedelic medicine, mental health therapeutics,
        life-extension technologies, ecosystem conservation, indigenous
        knowledge preservation, and more.
        <br />
        <br />
        Past grants and success stories include many firsts -- psilocybin for
        treatment-resistant depression, phase III clinical trials for
        MDMA-assisted psychotherapy for PTSD, the first dedicated psychedelic
        research center in the world (Imperial College London), the first
        dedicated psychedelic research center in United States (Johns Hopkins
        Medicine), the Harvard Law Project on Psychedelics Law and Regulation
        (POPLAR), The Ferriss – UC Berkeley Psychedelic Journalism Fellowship
        (overseen by Michael Pollan), and more. The above have led to study
        publications in leading journals (e.g., The Lancet, NEJM) and media
        coverage (NYT, WSJ, GQ, and dozens more) around the world.
        <br />
        <br />
        Funds will be used to further the above and pursue exciting new
        high-leverage bets in multiple fields. The Saisei Foundation was created
        by me to fund uncrowded bets that I think matter. Check out some of the
        projects here.
      </p>
    )
  },
  {
    question: "WHAT ON EARTH DOES “ELF” MEAN”?",
    answer: (
      <p>
        I don’t view this project as a PFP project, but I couldn’t find an apt
        label for what I’m doing, so I coined the term Emergent Long Fiction
        (ELF). Emergent Long Fiction (ELF) is an approach that I’m taking to
        fictional world-building, wherein even I, as the writer, don’t know the
        outcomes. But there’s a bit more to it. Let’s take the letters in order:
        <br />
        <br />
        It’s Emergent because I’m setting some of what I think of as the initial
        “C”s (characters, conditions, constraints, etc.) and then writing from
        that as a starting point. The journey and story lines are not
        predetermined; it emerges as lots of things coalesce and evolve.
        <br />
        <br />
        By analogy, it might be like going on a trip to Europe with five
        friends, and you decide you’re going to start in Spain and start walking
        east with no set schedule or list of destinations. You set some
        constraints: no travel by plane, for instance, and that people can be
        removed or added to the group, but perhaps the group can never exceed 10
        people. Then… you set off and see what adventures and misadventures
        develop.
        <br />
        <br />
        I have roughly 10-12 mini-chapters done as this point to set the stage
        (these will be released as text online and also as a new podcast, which
        is no small thing for me), and then I will be watching everything that
        happens around the launch and initial month closely, including responses
        to embedded easter eggs and mysteries, and I’ll craft the world moving
        forward based what people are excited about, what I’m most excited to
        write, friends’ input, what types of real-world communities develop, and
        much more.
        <br />
        <br />
        With each of my last five non-fiction books, I generally outline the
        entire book, and then it’s bricklaying. That means few surprises and
        little excitement for me as the author. This is different. The E of ELF
        means that, for the first time, my readers, listeners, fans, etc. will
        be able to watch me build a major project in real-time.
        <br />
        <br />
        The LF of ELF is “long fiction.” I decided to include “long” in this
        acronym so that any projects simply slapping a one-pager of half-assed
        lore on a website couldn’t easily use the ELF label.
        <br />
        <br />
        I have very specific parameters in mind to make this concrete. Generally
        speaking, short stories that are submitted for anthologies and so on do
        not exceed 10,000 words. There are definitely exceptions, but this is a
        reasonable cut-off. If that’s the upper limit for “short,” 10,000 words
        becomes the lower limit for “long.”
        <br />
        <br />
        So, an ELF project must have a STARTING base of more than 10,000 words.
        You can’t vomit out 300 words and say the other 9,700 are coming at some
        nebulous point in the future. So the “long fiction” sets a bar: 10,000+
        words need to be polished and ready BEFORE you launch. Then you build
        from there.
        <br />
        <br />
        Last, it’s called ELF because elves are fucking rad. When I played D&D
        ages ago, I was always a gray elf. I still have all of my modules from
        childhood. There will be multiple clans of elves in the COCKPUNCH world.
      </p>
    )
  },
  {
    question: "DO THESE NFTS COME WITH UTILITY, A ROADMAP, AND A DISCORD?",
    answer: (
      <p>
        No, sir! No, ma’am! There is no planned utility, no planned roadmap, and
        no planned Discord… but there might be a few surprises in store.
        <br />
        <br />
        As Barry Schwartz once said, “The secret to happiness is low
        expectations.” Please keep this in mind throughout this entire FAQ.{" "}
        <br />
        <br />
        <br />
        <br />
        And if I’m being honest after a few drinks, I dislike the overpromising
        roadmaps of a lot of web3, and it gets my knickers in a twist when
        artists feel like they all need to become tech startups CEOs to please
        speculators.
        <br />
        <br />
        HOWEVER, there are a few things I can say:
        <br />
        <br />
        1) So far, COCKPUNCH has easily been the most fun I’ve had in years.
        I’ve been waking up excited and going to bed laughing to myself.
        <br />
        <br />
        2) I will be launching a new fiction podcast dedicated to COCKPUNCH.
        It’s tentatively slated for one season of roughly 10 episodes. This will
        be my first new podcast since 20TK, and I’ll be cross-promoting it on my
        main podcast (The Tim Ferriss Show), promoting it across my social
        (totally roughly TK across platforms), and also including it in my
        newsletter to build momentum.
        <br />
        <br />
        3) The above podcast episodes will also be published in the written word
        on my blog, tim.blog, which for ages was (and might still be) one of the
        most popular blogs in the world. It is indexed *very* well after 1,000+
        blog posts, and I’m confident that readers will show up.
      </p>
    )
  },
  {
    question: "SHOULD I CONSIDER THIS AN INVESTMENT?",
    answer: (
      <p>
        No, no. Please, God, no.
        <br />
        <br />
        This is an art project for me, albeit a very ambitious one, and you are
        getting highly stylized JPGs that are part of a fictional world. That’s
        what you are signing up for. I will also do some promotion, be a dancing
        bear for a few interviews, and generally attempt to spread mirth and
        merriment.
        <br />
        <br />
        To be clear, this should not be your 401k, your next mortgage payment,
        or your kid’s college tuition. Ladies and gentlemen… this is called
        COCKPUNCH, for fuck’s sake.
        <br />
        <br />
        So if you’re primarily hoping to make a quick buck, please do not buy
        these JPGs! Cryptoland is a dangerous place. And if you’re a day trader
        who expects free money by yelling on the Internet when you’re unhappy,
        please do not participate in this project. There are plenty of other NFT
        projects that will promise the moon. This promises nothing but art and
        stories about cocks.
        <br />
        <br />
        ON THE OTHER HAND, if you can easily afford to lose anything you spend
        on such a JPG, and if you assume whatever you pay is for entertainment
        value only and will not have an ROI beyond entertainment (i.e., Might I
        get the same entertainment out of this as 1-2 trips to Disney World, a
        few nice “dinner and a movie” nights, or a few trips to Broadway?), then
        maybe, just maybe, COCKPUNCH can give you some soul-enriching “WTF?!”
        laughs and surprises that are worth the price of admission.
        <br />
        <br />
        So, JPG ticket to crazytown. You in?
      </p>
    )
  }
]
