import classNames from "classnames"
import { DateTime } from "luxon"
import useLocalTimeZone from "../hooks/useLocalTimeZone"

import strings from "../strings.json"
import {
  PUBLIC_PRICE,
  FIRST_MINT_DATE,
  ListConfiguration,
  LISTS,
  TOTAL_COUNT,
  useMinting
} from "../web3"
import Countdown from "./Countdown"
import MintButton from "./MintButton"

function getCurrentListDescription(
  isSoldOut: boolean,
  currentList: ListConfiguration | undefined,
  localTimeZone: string
) {
  if (isSoldOut) {
    return {
      title: "Mint sold out!",
      subtitle:
        "Thanks to everyone for minting an NFT! Missed it? Check out secondary listings on OpenSea!"
    }
  }

  if (currentList) {
    const description = strings.listTypes.find(
      (listType) => listType.name == currentList.name
    )
    const nextList = LISTS[LISTS.indexOf(currentList) + 1]
    const nextDescription =
      nextList &&
      strings.listTypes.find((listType) => listType.name == nextList.name)
    const subtitle =
      nextList && nextDescription ? (
        <span>
          {nextDescription.title} will start minting in{" "}
          <Countdown date={DateTime.fromSeconds(nextList.startsAt)} />
        </span>
      ) : (
        ""
      )
    return {
      title: `Now minting: ${description!.title}`,
      subtitle: subtitle
    }
  }

  return {
    title: `Each NFT will be ${PUBLIC_PRICE} ETH`,
    subtitle: `Minting begins
    ${FIRST_MINT_DATE.setZone(localTimeZone).toFormat("fff")}
    (${FIRST_MINT_DATE.setZone("UTC").toFormat("hh:ssa ZZZZ")})`
  }
}

export default function MintProgress() {
  const {
    count,
    isSuccess,
    isSoldOut,
    isLoading,
    activeList,
    isMintOver,
    hasMintedMax
  } = useMinting()
  const localTimeZone = useLocalTimeZone()
  const { title, subtitle } = getCurrentListDescription(
    isSoldOut,
    activeList,
    localTimeZone
  )

  const percent = Math.round((count / TOTAL_COUNT) * 100)
  const available = TOTAL_COUNT - count

  return (
    <>
      <a id="mint" />
      <div className="w-full">
        <MintButton />

        {!isLoading &&
          (isSuccess || hasMintedMax ? (
            <p className="bg-[#00171F] p-[24px] rounded-xl mt-[24px]">
              The reveal is currently slated for Friday, December 9th. Follow{" "}
              <a href="https://twitter.com/cockpunch/" target="_blank">
                @cockpunch
              </a>{" "}
              on Twitter for news.
            </p>
          ) : isMintOver ? null : (
            <div className="bg-[#00171F] p-[24px] rounded-xl mt-[24px] mb-[24px]">
              <p className="text-gray-200 font-[800]">{title}</p>
              <p className="text-gray-300 font-[400]">{subtitle}</p>
              {activeList && (
                <>
                  <div className="w-full rounded-full bg-teal-darker h-[8px] mt-[24px] mb-[8px]">
                    <div
                      className={classNames(
                        available < 1
                          ? "bg-green"
                          : "bg-gradient-to-r  from-teal to-teal-lighter",
                        "rounded-full h-[8px]"
                      )}
                      style={{ width: `${percent}%` }}
                    ></div>
                  </div>

                  <p>
                    <span className="text-white">
                      {available.toLocaleString()}
                    </span>{" "}
                    out of{" "}
                    <span className="text-white">
                      {TOTAL_COUNT.toLocaleString()}
                    </span>{" "}
                    supply remaining
                  </p>
                </>
              )}
            </div>
          ))}
      </div>
    </>
  )
}
