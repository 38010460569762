import classNames from "classnames"
import { DateTime } from "luxon"
import { Fragment } from "react"

import strings from "../strings.json"
import useLocalTimeZone from "../hooks/useLocalTimeZone"
import { FIRST_MINT_DATE, LISTS, useNow } from "../web3"
import MarkdownComponent from "./MarkdownComponent"
import useClientOnly from "../hooks/useClientOnly"

type Props = {
  className?: string
}

export default function Schedule(props: Props) {
  const { className } = props

  const localTimeZone = useLocalTimeZone()
  const clientOnly = useClientOnly()
  const now = useNow()

  const SCHEDULE = LISTS.map((list) => {
    const listDescription = strings.listTypes.find(
      (listType) => list.name == listType.name
    )
    return {
      time: DateTime.fromSeconds(list.startsAt),
      title: listDescription?.title ?? "Unknown List",
      description: listDescription?.description ?? "Unknown!"
    }
  })

  return (
    <>
      <a id="schedule" />
      <div className={classNames(className, "bg-gray-900 py-[120px]")}>
        <div className="mx-auto max-w-[800px] flex flex-col space-y-[40px]">
          <div className="">
            <h2 className="text-center mb-[16px]">
              {clientOnly ? (
                <>
                  Schedule for{" "}
                  {FIRST_MINT_DATE.setZone(localTimeZone).toLocaleString(
                    DateTime.DATE_FULL
                  )}{" "}
                  <span
                    style={{
                      fontFamily: "'Nunito Sans', sans-serif",
                      fontSize: "0.8em"
                    }}
                  >
                    ({FIRST_MINT_DATE.setZone(localTimeZone).offsetNameShort})
                  </span>
                </>
              ) : (
                "Schedule"
              )}
            </h2>
            <div className="text-center font-[300]">
              <MarkdownComponent markdown={strings.scheduleSubheadMarkdown} />
            </div>
            <p className="hidden text-center font-[300]">
              <a
                href="https://premint.xyz/cockpunch/"
                target="_blank"
                rel="noreferrer"
              >
                Click here
              </a>{" "}
              to check which list you&rsquo;re on.
            </p>
          </div>

          <div className="md:grid md:grid-cols-2 col-span-2 gap-[40px] ">
            <p className="hidden md:block text-teal-dark uppercase font-[800]">
              Time
              {clientOnly
                ? ` (${
                    DateTime.fromMillis(now).setZone(localTimeZone)
                      .offsetNameLong
                  })`
                : null}
            </p>
            <p className="hidden md:block text-teal-dark uppercase font-[800]">
              Mint opens for
            </p>

            {SCHEDULE.map((item) => {
              const { time, title, description } = item
              return (
                <Fragment key={title}>
                  <div>
                    <p className="mb-[8px] text-xl text-gray-200 font-[700]">
                      {time.setZone(localTimeZone).toFormat("h:mm a ZZZZ")}
                    </p>
                    <p className="text-sm">
                      ({time.setZone("UTC").toFormat("h:mm a ZZZZ")})
                    </p>
                  </div>
                  <div>
                    <p className="mb-[8px] text-xl text-gray-200 font-[700]">
                      {title}
                    </p>
                    <div className="mb-[40px] md:mb-0 text-gray-400">
                      <MarkdownComponent markdown={description} />
                    </div>
                  </div>
                </Fragment>
              )
            })}
          </div>
        </div>
      </div>
    </>
  )
}
