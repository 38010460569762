import { useState } from "react"
import { toast } from "react-toastify"

import {
  OPENSEA_SLUG,
  OPENSEA_URL,
  etherToFloat,
  useMinting,
  useAccountAutoconnect,
  ListConfiguration,
  PUBLIC_LIST
} from "../web3"
import strings from "../strings.json"
import { ConnectKitButton } from "connectkit"
import Countdown from "./Countdown"
import { DateTime } from "luxon"

const BUTTON_LAYOUT =
  "py-[20px] text-center text-black font-[800] block w-full rounded-full uppercase"
const TEAL_COLORS = "bg-teal bg-gradient-to-r from-teal to-teal-lightest"

const DISABLED_A_CLASSES = `opacity-50 bg-opacity-50 ${TEAL_COLORS} ${BUTTON_LAYOUT}`
const DISABLED_CLICKABLE_A_CLASSES = `cursor-pointer ${DISABLED_A_CLASSES}`

const DEFAULT_A_CLASSES = `cursor-pointer ${TEAL_COLORS} ${BUTTON_LAYOUT}`
const MINTING_A_CLASSES = `cursor-pointer flex space-x-[10px] justify-center bg-[#00384D] ${BUTTON_LAYOUT}`
const SUCCESS_A_CLASSES = `bg-green ${BUTTON_LAYOUT}`

export default function MintButton() {
  const { address } = useAccountAutoconnect()
  const {
    isSoldOut,
    isMintOver,
    isSuccess,
    isLoading,
    hasMintedMax,
    activeList,
    futureMintableNonPublicList,
    mintableAllowance
  } = useMinting()

  const personalizedOpenseaUrl = address
    ? `https://opensea.io/${address}/${OPENSEA_SLUG}`
    : OPENSEA_URL

  if (isSuccess) {
    return (
      <a
        href={personalizedOpenseaUrl}
        target="_blank"
        className={SUCCESS_A_CLASSES}
      >
        Mint Successful!
      </a>
    )
  }

  if (hasMintedMax) {
    return (
      <a
        href={personalizedOpenseaUrl}
        target="_blank"
        className={SUCCESS_A_CLASSES}
      >
        Your NFT has been minted
      </a>
    )
  }

  if (isLoading) {
    return <a className={DISABLED_A_CLASSES}>Loading ...</a>
  }

  if (isSoldOut) {
    return (
      <a className={DEFAULT_A_CLASSES} href={OPENSEA_URL}>
        Sold out. Buy on Opensea
      </a>
    )
  }

  if (isMintOver) {
    return (
      <a className={DISABLED_CLICKABLE_A_CLASSES} href={OPENSEA_URL}>
        Mint has ended!
      </a>
    )
  }

  if (!address) {
    return (
      <ConnectKitButton.Custom>
        {({ show }) => (
          <a className={DEFAULT_A_CLASSES} onClick={show}>
            Connect Wallet
          </a>
        )}
      </ConnectKitButton.Custom>
    )
  }

  if (activeList && mintableAllowance) {
    return <HasAccess priceFloat={etherToFloat(mintableAllowance.unitPrice)} />
  }

  return (
    <NoAccessYet futureMintableNonPublicList={futureMintableNonPublicList} />
  )
}

const NoAccessYet = ({
  futureMintableNonPublicList
}: {
  futureMintableNonPublicList?: ListConfiguration
}) => {
  const futureMintableNonPublicListDescription = futureMintableNonPublicList
    ? strings.listTypes.find(
        (listType) => futureMintableNonPublicList.name == listType.name
      )
    : null

  if (!futureMintableNonPublicList || !futureMintableNonPublicListDescription) {
    return (
      <div>
        <p className="flex items-start space-x-2 bg-[#111111] p-[12px] rounded-xl mb-[24px]">
          <img src="/icons/warning.svg" alt="warning" />
          <span className="text-gray-200">
            Your wallet isn't on an allowlist, but will be able to mint
            during the Public period. <a href="#schedule">See schedule</a>
          </span>
        </p>
        <a className={DISABLED_A_CLASSES}>
          Mint in{" "}
          <Countdown date={DateTime.fromSeconds(PUBLIC_LIST.startsAt)} />
        </a>
      </div>
    )
  }

  return (
    <div>
      <p className="flex items-start space-x-2 bg-[#111111] p-[12px] rounded-xl mb-[24px]">
        <img src="/icons/success.svg" alt="success" />
        <span className="text-gray-200">
          Your wallet will be able to mint during the{" "}
          <strong>{futureMintableNonPublicListDescription.title}</strong>{" "}
          period. <a href="#schedule">See schedule</a>
        </span>
      </p>
      <a className={DISABLED_A_CLASSES}>
        Mint in{" "}
        <Countdown
          date={DateTime.fromSeconds(futureMintableNonPublicList.startsAt)}
        />
      </a>
    </div>
  )
}

const HasAccess = ({ priceFloat }: { priceFloat: number }) => {
  const { mint, isMinting } = useMinting()

  const [hasAgreed, setHasAgreed] = useState(false)

  return (
    <div>
      <p className="flex items-start space-x-2 bg-[#111111] p-[12px] rounded-xl mb-[24px]">
        <img src="/icons/success.svg" alt="success" />
        <span className="text-gray-200">Your wallet is ready to mint!</span>
      </p>
      <div className="text-left mb-[10px] ml-[5px] pb-[24px]">
        <label>
          <input
            type="checkbox"
            name="time"
            className="mr-[8px]"
            checked={hasAgreed}
            onChange={() => setHasAgreed(!hasAgreed)}
          />
          I agree to the
          <a
            href="http://tim.blog/cockpunch-license"
            rel="noopen nofollow noreferrer"
            target="_blank"
            className="underline text-gray-400 ml-[4px]"
          >
            NFT License Agreement
          </a>
          ,
          <a
            href="http://tim.blog/tos"
            rel="noopen nofollow noreferrer"
            target="_blank"
            className="underline text-gray-400 ml-[4px]"
          >
            Terms of Service
          </a>
          , and
          <a
            href="http://tim.blog/privacy"
            rel="noopen nofollow noreferrer"
            target="_blank"
            className="underline text-gray-400 ml-[4px]"
          >
            Privacy Policy
          </a>
        </label>
      </div>

      <div className="flex space-x-[24px] items-center">
        {isMinting ? (
          <a className={MINTING_A_CLASSES}>
            <img
              src="/spinner.svg"
              className="animate-spin-slow"
              alt="Spinner"
            />
            <span className="text-white">Your NFT is minting...</span>
          </a>
        ) : (
          <a
            className={DEFAULT_A_CLASSES}
            onClick={() => {
              if (!hasAgreed) {
                toast("Please agree to the terms of sale in order to mint.", {
                  position: "bottom-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  theme: "dark",
                  type: "warning"
                })
              } else {
                mint && mint()
              }
            }}
          >
            <span className="flex items-center justify-center space-x-[8px] font-serif text-[40px] font-[700]">
              <span className="text-black">
                Mint for{" "}
                <span className="font-[800] text-black">{priceFloat} ETH</span>
              </span>
            </span>
          </a>
        )}
      </div>
    </div>
  )
}
