import { marked } from "marked"

let CACHED_HTML: { [input: string]: string } = {}

export default function MarkdownComponent({ markdown }: { markdown: string }) {
  if (!CACHED_HTML[markdown]) {
    CACHED_HTML[markdown] = marked
      .parse(markdown)
      .replace(/<a href="/g, '<a target="_blank" href="')
  }

  return <div dangerouslySetInnerHTML={{ __html: CACHED_HTML[markdown] }} />
}
