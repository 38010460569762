import classNames from "classnames"

import Mint from "./Mint"
import strings from "../strings.json"
import MarkdownComponent from "./MarkdownComponent"
import { OPENSEA_URL } from "../web3"
import { CONTRACT_ADDRESS } from "../web3/config"


type Props = {
  className?: string
}

export default function Hero(props: Props) {
  const { className } = props

  return (
    <div
      className={classNames(
        className,
        "max-w-[1200px] m-auto my-[62px] md:flex justify-between md:space-x-[80px]"
      )}
    >
      <div className="w-full">
        <img src="/555-image.png" alt="" width={560} />
      </div>

      <div className="max-w-[580px] m-auto flex flex-col items-start space-y-[10px]">
        <div className="space-y-[16px]">
          <h1>{strings.name}</h1>
          <MarkdownComponent markdown={strings.descriptionMarkdown} />
        </div>

        <Mint />

        <div className="flex items-center mt-[24px] md:mt-0">
          Official Links:
          <a
            href="https://twitter.com/cockpunch/"
            target="_blank"
            rel="nofollow noreferrer"
            className="m-auto"
          >
            <img src="/twitter.svg" alt="" className="h-5 ml-4 fill-white" />
          </a>
          <a
            href={OPENSEA_URL}
            target="_blank"
            rel="nofollow noreferrer"
            className="m-auto"
          >
            <img src="/opensea.svg" alt="" className="h-5 ml-4 fill-white" />
          </a>
          <a
            href={"https://etherscan.io/address/" + CONTRACT_ADDRESS + "#code"}
            target="_blank"
            rel="nofollow noreferrer"
            className="m-auto"
          >
            <img src="/etherscan.svg" alt="" className="h-5 ml-4 fill-white" />
          </a>
        </div>
      </div>
    </div>
  )
}
