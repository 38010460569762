import classNames from "classnames"
import type { NextPage } from "next"
import Head from "next/head"
import { useRouter } from "next/router"
import strings from "../strings.json"

import FAQ from "../components/FAQ"
import Footer from "../components/Footer"
import Header from "../components/Header"
import Hero from "../components/Hero"
import Schedule from "../components/Schedule"
import Stats from "../components/Stats"

const Home: NextPage = () => {
  const margins = "px-[24px] md:px-10"
  const { query } = useRouter()

  return (
    <div>
      <Head>
        <title>{strings.name + " Mint Page | Powered by PREMINT"}</title>
        <link rel="icon" href="/favicon.ico" />
        <meta name="description" content={"This is the official mint page for " + strings.name + "."} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={strings.name + " Mint Page | Powered by PREMINT"} />
        <meta property="og:url" content="https://cockpunch.premint.xyz/" />
        <meta property="og:image" content="https://cockpunch.premint.xyz/meta-image.png" />
        <meta property="og:site_name" content="PREMINT" />
        <meta property="og:description" content={"This is the official mint page for " + strings.name + "."} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={strings.name + " Mint Page | Powered by PREMINT"} />
        <meta name="twitter:description" content={"This is the official mint page for " + strings.name + "."} />
        <meta name="twitter:image:src" content="https://cockpunch.premint.xyz/meta-image.png" />
        <meta name="twitter:domain" content="https://cockpunch.premint.xyz/" />
      </Head>

      <Header />

      <div>
        <Hero className={margins} />
        <Schedule className={margins} />
        <FAQ />
        <Footer />
      </div>
    </div>
  )
}

export default Home
