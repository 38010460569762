export default function Footer() {
  return (
    <div
      className="px-[32px] py-[36px] flex flex-col md:flex-row justify-between bg-black"
      style={{ boxShadow: "0px 1px 16px #5CDEFF" }}
    >
      <div className="flex flex-col md:flex-row items-center space-y-[24px] md:space-y-0 md:space-x-[32px]">
        <span className="text-xs text-gray-500 font-[700] uppercase">
          &copy; 2022 Premint, Inc
        </span>
        <a
          href="https://www.premint.xyz/terms/"
          target="_blank"
          rel="nofollow noreferrer"
          className="text-gray-300"
        >
          Terms
        </a>
        <a
          href="https://www.premint.xyz/terms/"
          target="_blank"
          rel="nofollow noreferrer"
          className="text-gray-300"
        >
          Privacy policy
        </a>
      </div>

      <div className="flex items-center mt-[24px] md:mt-0">
        <a
          href="https://twitter.com/PREMINT_NFT"
          target="_blank"
          rel="nofollow noreferrer"
          className="m-auto"
        >
          <img src="/twitter.svg" alt="" className="h-4 ml-4 fill-white" />
        </a>
      </div>
    </div>
  )
}
