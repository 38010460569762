import { DateTime } from "luxon"
import { useEffect, useState } from "react"

export default function useLocalTimeZone() {
  const [timeZone, setTimeZone] = useState("America/Los_Angeles")
  useEffect(() => {
    // Only do this on client.
    setTimeZone(DateTime.local().zoneName)
  }, [])
  return timeZone
}
