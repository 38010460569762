import { DateTime } from "luxon"
import { useCallback } from "react"
import ReactCountdown, { CountdownRenderProps } from "react-countdown"
import { useNow, BEGINNING_OF_TIME } from "../web3"

export default function Countdown({ date }: { date: DateTime }) {
  const now = useNow()
  const renderer = useCallback(
    ({ days, hours, minutes }: CountdownRenderProps) => {
      const parts = [
        days > 0 ? `${days} day${days === 1 ? "" : "s"}` : null,
        hours > 0 ? `${hours} hour${hours === 1 ? "" : "s"}` : null,
        minutes > 0 ? `${minutes} minute${minutes === 1 ? "" : "s"}` : null
      ].filter(Boolean)
      return <>{parts.length > 0 ? parts.join(", ") : "just a moment"}</>
    },
    []
  )

  return now === BEGINNING_OF_TIME ? (
    <span>...</span>
  ) : (
    <ReactCountdown
      now={() => now}
      date={date.toString()}
      renderer={renderer}
    />
  )
}
